import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import residentialGates from '../assets/images/residential-gates.jpg'
import commercialGates from '../assets/images/commercial-gates.jpg'

class GatesPage extends React.Component {
  render() {
    const siteTitle =
      'Custom Gate Services - Commercial Gates - Residential Gates'
    const siteDescription =
      'We provide custom automatic gate installation, repair, and maintenance of residential and commercial properties in Dallas, Fort Worth, and Arlington. Contact us today to get an estimate on a gate for your home or place of business!'

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Gates - J&J Automatic Service & Design"
          description={siteDescription}
        />
        <div className="container-fluid text-center headline gates-headline">
          <div className="container">
            <h1 className="text-white m-0">Custom Gates</h1>
            <hr />
          </div>
        </div>
        <div className="container gates-container">
          <div className="row flex-column-reverse flex-md-row mb-5">
            <div className="col-md-12 text-justify" id="gates-mobile">
              <p className="mx-auto">
                If you are looking for some extra security for your residential
                or commercial property, J&J Gates can provide you with a{' '}
                <strong>custom built gate</strong> and{' '}
                <strong>professional installation</strong>. We offer gates
                services all over the DFW area with centrally located offices in{' '}
                <strong>Dallas</strong>, <strong>Fort Worth</strong>,{' '}
                <strong>Arlington</strong>, and <strong>Burleson, TX</strong>.
                Give us a call today at{' '}
                <a href="tel:8174662794">817-466-2794</a> to learn more about
                our <Link to="/gates/gate-designs/">gate designs</Link> and
                services.
              </p>
            </div>
          </div>
          <div className="row flex-column-reverse flex-md-row d-padding-b">
            <div className="col-md-7 text-justify">
              <h4 className="mt-0">Residential Gates</h4>
              <hr />
              <p>
                One of the most common uses of gates is for security. They keep
                unwanted visitors out, not only by blocking them from entering
                but also by alerting them that there might be more security
                beyond that point as well. Research has shown that burglars are
                much more likely to move on to another house without even
                considering one that has a gate.
              </p>
              <p>
                Video and audio surveillance systems can also be attached to
                allow the user to grant access once they have confirmed the
                identity of the person trying to enter.
              </p>
              <p>
                Besides keeping people out, they can also be used to keep them
                in. With a proper enclosure, children can be allowed to roam
                free without constant supervision. Also, pets can be kept in an
                enclosed area while still being able to get the exercise they
                need. We specialize in installing automatic driveway gates and
                fences for residences, so you can rest easy knowing your
                property is safe and secure.
              </p>
            </div>
            <div className="col-md-5 mt-4">
              <LazyLoadImage
                className="w-100 mt-5"
                src={residentialGates}
                alt="Residential Gate"
              />
            </div>
          </div>

          <div className="row gates-row2">
            <div className="col-md-5 mt-4">
              <LazyLoadImage
                className="w-100 mt-5"
                src={commercialGates}
                alt="Commercial Gate"
              />
            </div>
            <div className="col-md-7 text-justify">
              <h4 className="mt-0">Commercial Gates</h4>
              <hr />
              <p>
                Many commercial properties also use gates to restrict access,
                define boundaries, and enhance property appearance. The most
                common are sliding gates. This is because commercial properties
                often face busy roads, making it impossible for the gate to
                swing open.
              </p>
              <p>
                Because they get so much use, they often require maintenance and
                repair. A properly maintained commercial gate will continue to
                work as intended and will remain to look great to positively
                reflect your company’s image.
              </p>
              <p>Learn more about our custom commercial gate services.</p>
            </div>
          </div>

          <div className="gate-types d-padding-t">
            <h4 className="text-center mt-0 ">Types of Custom Gates</h4>
            <hr />
            <p className="mt-2 text-center">
              We offer a variety of custom gates for both homes and places of
              business. Here are some of the gates we offer:
            </p>
            <div className="row gates-row3 text-center">
              <div className="col-xl-2 col-lg-4 col-md-4 offset-xl-1 offset-lg-2">
                <Link to="/gates/gate-types/automatic-gates">
                  <div className="gate-type-cont">
                    <h5>Automatic</h5>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-4">
                <Link to="/gates/gate-types/security-gates/">
                  <div className="gate-type-cont">
                    <h5>Security</h5>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-4">
                <Link to="/gates/gate-types/driveway-gates/">
                  <div className="gate-type-cont">
                    <h5>Driveway</h5>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-4">
                <Link to="/gates/gate-types/electric-gates/">
                  <div className="gate-type-cont">
                    <h5>Electric</h5>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-4">
                <Link to="/gates/gate-types/sliding-gates/">
                  <div className="gate-type-cont">
                    <h5>Sliding</h5>
                  </div>
                </Link>
              </div>
            </div>

            <div className="row gates-row4 text-center mt-5">
              <div className="col-xl-2 col-lg-4 col-md-6 offset-lg-2">
                <Link to="/gates/gate-types/chain-link-gates/">
                  <div className="gate-type-cont">
                    <h5>Chain Link</h5>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6">
                <Link to="/gates/gate-types/wooden-gates/">
                  <div className="gate-type-cont">
                    <h5>Wood</h5>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6">
                <Link to="/gates/gate-types/wrought-iron-gates/">
                  <div className="gate-type-cont">
                    <h5>Wrought Iron</h5>
                  </div>
                </Link>
              </div>
              <div className="col-xl-2 col-lg-4 col-md-6">
                <Link to="/gates/gate-types/estate-gates/">
                  <div className="gate-type-cont">
                    <h5>Estate</h5>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="gates-row5 d-padding-t set-width text-center mx-auto">
            <h4 className="mt-0">Custom Gate Services We Offer</h4>
            <hr />
            <div className="col-md-12">
              <p className="mt-2">
                Our company not only installs gates but also offers{' '}
                <Link to="/gates/services/">gate servicing</Link> and{' '}
                <strong>repairs</strong> in{' '}
                <strong>
                  Arlington and the greater Dallas-Fort Worth metroplex
                </strong>
                . Adding a gate to your home or business can increase its value
                dramatically, especially for large properties. It can be hard to
                visualize the size of a property without an enclosure. Land that
                is surrounded by fencing and gates will allow appraisers and
                potential buyers to better understand what the property
                includes. Beautiful entry gates can also make for a grand
                entrance which may, in itself, raise the value of a home.
              </p>
              <p>
                We service all types of gates including those that are simple,
                use high-tech devices, and even antique gates. Give us a call
                today at <a href="tel:8174662794">817-466-2794</a> so we can
                provide you with a <Link to="/contact-us/">free estimate.</Link>
              </p>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default GatesPage

export const GatesPageQuery = graphql`
  query GatesPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
